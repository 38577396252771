import { Injectable } from '@angular/core';
import { AnimationSet } from '../interfaces/animationset';
import { ContentSet } from '../interfaces/contentset';
import { Dataset } from '../interfaces/dataset';
import { VideoFormat } from '../interfaces/videoformat';
import { ContentProvider } from '../providers/content-provider'
import { AnimationProvider } from './animation-provider';
import { DatasetProvider } from './dataset-provider';
import { StorageService } from './storage-service';

@Injectable({
    providedIn: 'root'
})
export class ContentSetGlobal {
constructor(
  public contentProvider: ContentProvider,
  private animationProvider: AnimationProvider,
  private datasetProvider: DatasetProvider,
  private storageService: StorageService) {
  }

  CurrentContentSetVideo: ContentSet;
  CurrentContentSetVideoDataset : Dataset;
  videoFormats: VideoFormat[];
  data: Dataset[];
  animationSets: AnimationSet[];

  masterDataset: Dataset;
  masterDataLoading = true;

  exiting:boolean = false;
  saveMessage:string = "";
  loading = true;

  overallLoading = false;
  datasetLoading = false;

  load(){

    this.overallLoading = true;
    this.loading = true;

    return new Promise<void>(async (resolve, reject) => {

      //load local variables
      var localLoadPromise = this.loadFromLocal();

      //get video formats
      var formatPromise = new Promise<void>(async (innerresolve, reject) => {
        this.animationProvider.getVideoFormats().subscribe(response => {
          this.videoFormats = response;
          innerresolve()
        });
      });

      //return when all loaded
      Promise.all([localLoadPromise, formatPromise]).then(() => {
        resolve();
        this.overallLoading = false;

        if(!this.overallLoading && !this.datasetLoading)
          this.loading = false;
      });

    });
  }

  loadDataset(datasetId){
    this.datasetLoading = true;
    this.loading = true;

    this.datasetProvider.getDatasets(datasetId, false, true).subscribe(result => {
      this.CurrentContentSetVideoDataset = result[0];
      this.datasetLoading = false;

      if(!this.overallLoading && !this.datasetLoading)
        this.loading = false;
    });

  }

  async storeLocally() {
    await this.storageService.set("CurrentContentSetVideo", this.CurrentContentSetVideo);
    // await this.storageService.set("CurrentContentSetVideoDataset", this.CurrentContentSetVideoDataset);
  }

  async loadFromLocal() {
    return new Promise<void>(async (resolve, reject) => {
      var currentContentSetVideo = await this.storageService.get("CurrentContentSetVideo");
      if (currentContentSetVideo != '')
        this.CurrentContentSetVideo = currentContentSetVideo;

      // var currentContentSetVideoDataset = await this.storageService.get("CurrentContentSetVideoDataset");
      // if (currentContentSetVideoDataset != '')
      //   this.CurrentContentSetVideoDataset = currentContentSetVideoDataset;

      resolve();
    });
  }

  save() {

    this.storeLocally();

    //save content set
    this.contentProvider.saveContentSetVideo(this.CurrentContentSetVideo).subscribe(response => {
      if(response == "success"){
        return true;
      }
      else{
        this.saveMessage = "Error saving mappings: " + response;
       return false;
      }
    });
  }
}