import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-settings-popover',
  templateUrl: './settings-popover.component.html',
  styleUrls: ['./settings-popover.component.scss'],
})
export class SettingsPopoverComponent implements OnInit {

  isMoreInformation:boolean = false;
  itemlist: any[] = [];
  header: string;
  showClearFilter: boolean = true;
  customEvents: boolean = false;
  
    constructor(
      public popoverController: PopoverController, 
      public navParams: NavParams) {
  
        this.itemlist = navParams.get('itemlist');
        this.header = navParams.get('header');
        this.isMoreInformation = navParams.get('isMoreInformation');
        this.showClearFilter = navParams.get('showClearFilter');
        this.customEvents = navParams.get('customEvents');
    }

    ngOnInit(){

    }
  
    selectItem(event, item){
      event.preventDefault();
      this.popoverController.dismiss({ itemSelected: item });
    }

    customClick(item){
      item.click();
      this.popoverController.dismiss();
    }
  
    clearFilter(event){
      event.preventDefault();
      this.popoverController.dismiss({ animationSelected: "clear__filter" });
    }
  }
  
