import { Plugins } from "@capacitor/core";
import { Injectable } from '@angular/core';

const { Storage } = Plugins;

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor() {}

    async set(key: string, value: any): Promise<void> {
        return new Promise(async (resolve, reject) =>{
            await Storage.set({
                key: key,
                value: JSON.stringify(value)
            });
            resolve();
        });
    }

    async get(key:string): Promise<any>{
        return new Promise(async (resolve, reject) =>{
            var data =  await Storage.get({ key: key });
            var processed = "";
            if(data.value){
                processed = JSON.parse(data.value);
            }
            resolve(processed);
        });
    }

    async remove(key: string): Promise<void> {
        return Storage.remove({
            key: key
        });
    }
}