import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'learn-more',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./pages/review/review.module').then( m => m.ReviewPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'mobile-splash',
    loadChildren: () => import('./pages/mobile-splash/mobile-splash.module').then( m => m.MobileSplashPageModule)
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./pages/_admin/admin-home/admin-home.module').then( m => m.AdminHomePageModule)
  },
  {
    path: 'user-accounts',
    loadChildren: () => import('./pages/_admin/user-accounts/user-accounts.module').then( m => m.UserAccountsPageModule)
  },
  {
    path: 'manage-purchases',
    loadChildren: () => import('./pages/_admin/manage-purchases/manage-purchases.module').then( m => m.ManagePurchasesPageModule)
  },
  {
    path: 'learn',
    loadChildren: () => import('./pages/learn/learn.module').then( m => m.LearnPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'sales-prospects',
    loadChildren: () => import('./pages/_admin/sales-prospects/sales-prospects.module').then( m => m.SalesProspectsPageModule)
  },
  {
    path: 'sales-sequence',
    loadChildren: () => import('./pages/_admin/sales-sequence/sales-sequence.module').then( m => m.SalesSequencePageModule)
  },
  {
    path: 'sales-email-templates',
    loadChildren: () => import('./pages/_admin/sales-email-templates/sales-email-templates.module').then( m => m.SalesEmailTemplatesPageModule)
  },
  {
    path: 'sales-todo',
    loadChildren: () => import('./pages/_admin/sales-todo/sales-todo.module').then( m => m.SalesTodoPageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then( m => m.PricingPageModule)
  },
  {
    path: 'data',
    loadChildren: () => import('./pages/data/data.module').then( m => m.DataPageModule)
  },
  {
    path: 'animations',
    loadChildren: () => import('./pages/animations/animations.module').then( m => m.AnimationsPageModule)
  },
  {
    path: 'data-profile',
    loadChildren: () => import('./components/data-profile/data-profile.module').then( m => m.DataProfileModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./pages/content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'create-general',
    loadChildren: () => import('./pages/create-pages/general/general.module').then( m => m.GeneralPageModule)
  },
  {
    path: 'create-data',
    loadChildren: () => import('./pages/create-pages/data/data.module').then( m => m.DataPageModule)
  },
  {
    path: 'create-animations',
    loadChildren: () => import('./pages/create-pages/animations/animations.module').then( m => m.AnimationsPageModule)
  },
  {
    path: 'create-mappings',
    loadChildren: () => import('./pages/create-pages/mappings/mappings.module').then( m => m.MappingsPageModule)
  },
  {
    path: 'create-render',
    loadChildren: () => import('./pages/create-pages/render/render.module').then( m => m.RenderPageModule)
  },
  {
    path: 'upload',
    loadChildren: () => import('./pages/_designer/upload/upload.module').then( m => m.UploadPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'mint',
    loadChildren: () => import('./pages/create-pages/mint/mint.module').then( m => m.MintPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
