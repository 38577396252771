import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dataset } from '../interfaces/dataset';
import { Globals } from './globals'

@Injectable({
    providedIn: 'root'
})
export class DatasetProvider {
    datasets: Dataset[] = [];
    apiGetDatasetsMethod = "getDatasets";
    apiSaveDatasetsMethod = "saveDatasets";

    constructor(
        public http: HttpClient, 
        public globals: Globals) {}

    load(dataSetId = null, master = false, refresh): any {
        
        //filter by campaign
        var localDataSets;
        if(dataSetId != null)
            localDataSets = this.datasets.filter(a => a.id == dataSetId);
        else    
            localDataSets = this.datasets;

        if (localDataSets.length > 0 && !refresh) {
            return of(localDataSets);
        } else {
            try{

                let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                var body = { 
                    id: dataSetId,
                    brandId: this.globals.brandId,
                    userId : this.globals.userId,
                    master : master
                };

                return this.http
                .post<any>(this.globals.apiDatasetUrl + "?o=" + this.apiGetDatasetsMethod, body, { headers: headers })
                .pipe(map(this.processData, this))
                .pipe(catchError(this.handleHttpError));
            }
            catch(ex){
                this.globals.logout();
                return false;
            }
        }
    }

    processData(data: any) {
        this.datasets = data.response;
        return this.datasets;
    }

    getDatasets(datasetId = null, master = false, refresh){
        return this.load(datasetId, master, refresh).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getDatasetById(datasetId, master = false, refresh){
        return this.getDatasets(datasetId,  master, refresh).pipe(map((data:any[]) => {
            return data.filter(r => r.id == datasetId);
        }));
    }

    getDatasetMaster(refresh){
        return this.getDatasets(null, true, refresh).pipe(map((data:any[]) => {
            return data[0];
        }));
    }

    saveDataset(dataSet) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");


            var body = {
                brandId: this.globals.brandId,
                userId : this.globals.userId,
                datasets: [dataSet]
            }

            return this.http
            .post<any>(this.globals.apiDatasetUrl + "?o=" + this.apiSaveDatasetsMethod, body, { headers: headers })
            .pipe(map(this.processDatasetData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    processDatasetData(data: any) {
        return data.response;
    }

    handleHttpError = (error: HttpErrorResponse) => {
        let errorMessage = 'Unknown error!';
        if (error.error.type == "error" && error.error.err) {
            // Custom errors
            if(error.error.err.message)
                errorMessage = `Error: ${error.error.err.message}`;
            else
                errorMessage = `Error: ${error.error.err}`;
        }
        else if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } 
        else {
          // Server-side errors
          errorMessage = error.message;
        }
        return of(errorMessage);
      }

}