import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavParams, IonicModule } from '@ionic/angular';
import { SettingsPopoverComponent } from './settings-popover.component'


@NgModule({
  declarations: [SettingsPopoverComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  providers: [NavParams],
  exports:[SettingsPopoverComponent]
})
export class SettingsPopoverModule { }
