import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ContentSet } from '../interfaces/contentset';
import { Globals } from './globals'

@Injectable({
    providedIn: 'root'
})
export class ContentProvider {
    contentSetList: ContentSet[];
    apiGetContentSetMethod = "getContentSetVideo";
    apiSaveContentSetMethod = "saveContentSetVideo";
    apiDeleteContentSetMethod = "deleteContentSetVideo";
    apiDeleteRenderSetMethod = "deleteRenderSet";

    constructor(
        public http: HttpClient, 
        public globals: Globals) {}

    load(contentSetId, refresh): any {
        
        if (!refresh) {
            return of(this.contentSetList);
        } else {
            try{

                let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                var body = {
                    contentSetId: contentSetId,
                    brandId: this.globals.brandId
                }

                return this.http
                .post<any>(this.globals.apiContentUrl + "?o=" + this.apiGetContentSetMethod, body, { headers: headers })
                .pipe(map(this.processData, this))
                .pipe(catchError(this.globals.handleHttpError));
            }
            catch(ex){
                this.globals.logout();
                return false;
            }
        }
    }

    processData(data: any) {
        this.contentSetList = data.response;
        return this.contentSetList;
    }

    saveContentSetVideo(contentSet) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                brandId: this.globals.brandId,
                userId : this.globals.userId,
                contentSet: contentSet
            }

            return this.http
            .post<any>(this.globals.apiContentUrl + "?o=" + this.apiSaveContentSetMethod, body, { headers: headers })
            .pipe(map(this.processSavedData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    deleteContentSetVideo(id) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                brandId: this.globals.brandId,
                userId : this.globals.userId,
                id: id
            }

            return this.http
            .post<any>(this.globals.apiContentUrl + "?o=" + this.apiDeleteContentSetMethod, body, { headers: headers })
            .pipe(map(this.processSavedData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    deleteRenderSet(id) : any {

        try{
            let headers = new HttpHeaders().set('Authorization', this.globals.idToken);
            headers = headers.set("Content-Type", "application/json; charset=utf-8");

            var body = {
                brandId: this.globals.brandId,
                userId : this.globals.userId,
                id: id
            }

            return this.http
            .post<any>(this.globals.apiContentUrl + "?o=" + this.apiDeleteRenderSetMethod, body, { headers: headers })
            .pipe(map(this.processSavedData, this))
            .pipe(catchError(this.handleHttpError));
        }
        catch(ex){
            return of("Error saving: Likely a problem with backend API.");
        }
    }

    getContentSetsVideo(contentSetId = null, refresh){
        return this.load(contentSetId, refresh).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    processSavedData(data: any) {
        return data.response;
    }


    handleHttpError = (error: HttpErrorResponse) => {
        let errorMessage = 'Unknown error!';
        if (error.error.type == "error" && error.error.err) {
            // Custom errors
            if(error.error.err.message)
                errorMessage = `Error: ${error.error.err.message}`;
            else
                errorMessage = `Error: ${error.error.err}`;
        }
        else if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } 
        else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return of(errorMessage);
      }
}