import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { StorageService } from '../providers/storage-service';
import { ToastNotificationComponent } from "../pages/toast-notification/toast-notification.component";
import { Location } from '@angular/common'
import { HttpErrorResponse } from "@angular/common/http";
import { throwError, BehaviorSubject } from 'rxjs';
import { AWSData } from '../providers/aws';
import { Utils } from '../providers/utils';
import { ContentSet } from '../interfaces/contentset';
import { truncateSync } from 'fs';
import { Boolean } from 'aws-sdk/clients/appstream';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  constructor(
    public navCtrl: NavController,
    public location: Location,
    public storageService: StorageService,
    public toast: ToastNotificationComponent,
    public aws: AWSData) {

  }

  //Base Api Url
  apiUrl: string = "https://api.contentjaguar.com/";

  //Platform API Urls
  apiBaseUrl: string = "https://7uasdigzgf.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-base";
  apiShareUrl: string = "https://g4p4mdpt3g.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-share";
  apiAnimationsUrl: string = "https://hzwj62njgc.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-animation";
  apiMappingsUrl: string = "https://v6bcqhw7gc.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-mapping";
  apiMediaManagerUrl: string = "https://elonpgb714.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-media-manager";
  apiContentUrl:string = "https://1swpvrhyl6.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-content";
  apiDatasetUrl:string = "https://mh8lxupnda.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-dataset";
  apiRenderAfterEffects:string = "https://wdyy1q0o8h.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-render";
  apiBrandUrl: string = "https://rdg08ysmd3.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-brand";
  apiReviewUrl: string = "https://q36yf5qsae.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-review";

  //Admin API Urls
  apiAdminBaseUrl: string = "https://q9b1u7wnb4.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-admin-base";
  apiAdminUsersUrl: string = "https://abeicep4fi.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-admin-users"
  apiAdminSalesUrl: string = "https://zfg613bgok.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-admin-sales";

  brandId: string = '';
  brandInfo: any = null;

  contentJobs: ContentSet[] = [];

  isPurchaseMode: boolean = false;
  idToken: string = '';
  wifiOnlyDownload: boolean = false;
  isMultiBrand: boolean = false;
  networkHandler: any = {};
  userName: string = "";
  userId: string = "";
  isMobileApp: boolean = false;
  isAndroid: boolean = false;
  menuOpen: boolean = true;
  loggedIn: boolean = false;
  isMobileBrowser: boolean = false;
  isTutorialOpen: boolean = false;

  isAdministrator: boolean = false;
  isDesigner: boolean = false;

  hasPurchased: boolean = false;

  isDownloading: boolean = false;
  isUploading: boolean = false;
  downloadUrl: string = "";
  downloadProgress: number = 0.05;

  networkStatus: any = {};

  creatorPages = [
    {
      title: 'Create',
      click : () => {
        this.navCtrl.navigateForward(['content']);
      },
      icon: 'color-wand',
      hidden: false,
      disabled: false,
      selected: "selected"
    },
    {
      title: 'Animations',
      click : () => {
        this.navCtrl.navigateForward(['animations']);
      },
      icon: 'videocam',
      hidden: false,
      disabled: false,
      selected: ""
    },
    {
      title: 'Data',
      click : () => {
        if(window.location.pathname == "/data")
          window.location.reload();
        else
          this.navCtrl.navigateForward(['data']);
      },
      icon: 'finger-print',
      hidden: false,
      disabled: false,
      selected: ""
    },
    {
      title: 'Review',
      click : () => {
        this.navCtrl.navigateForward(['review']);
      },
      icon: 'eye',
      hidden: false,
      disabled: false,
      selected: ""
    },
    {
      title: 'Publish',
      click : () => {
        this.navCtrl.navigateForward(['publish']);
      },
      icon: 'rocket',
      hidden: true,
      disabled: true,
      selected: ""
    },
    {
      title: 'Share',
      click : () => {
        this.navCtrl.navigateForward(['share']);
      },
      icon: 'share-social',
      hidden: true,
      disabled: true,
      selected: ""
    }
  ];

  designerPages = [
    {
      title: 'Uploads',
      click : () => {

        if(window.location.pathname == "/upload")
          window.location.reload();
        else
          this.navCtrl.navigateForward(['upload']);
      },
      icon: 'cloud-upload',
      hidden: false,
      disabled: false,
      selected: "selected",
    },
    {
      title: 'Create',
      icon: 'color-wand',
      url: null,
      hidden: false,
      disabled: false,
      selected: "",
      click : () => {
        this.saveIsDesigner(false);
        this.navigateToCreatorHome(false, true);
      }
    }
  ];

  appPages = [];

  async login() {
    return new Promise<Boolean>(async (resolve, reject) => {

      this.populateUserName();
      this.populatePurchaseMode();
      this.populateWifiOnlySetting();
      this.populateIsMultiBrand();

      Promise.all([
        this.populateAuthToken(),
        this.populateBrandId(),
        this.populateUserId(),
        this.populateBrandData(),
        this.populateIsAdministrator(),
        this.populateHasPurchased(),
        this.populateDownloadInfo(),
        this.populateIsDesigner()
        ]).then(async () => {
          var loggedIn = await this.checkIfLoggedIn();
          if(loggedIn){
            this.loadMenuItems();
            resolve(true);
          }
          else{
            this.logout(false);
            resolve(false);
          }
            
        });
    });
  }

  navigateToSignIn = () => {
    this.navCtrl.navigateForward(['sign-in']);
  }

  loadMenuItems(){

      //set nav menu for designer or creator
    if(this.isDesigner)
      this.appPages = this.designerPages;
    else
      this.appPages = this.creatorPages;

      //remove hidden nav items
      this.appPages = this.appPages.filter(p => !p.hidden);
  }

  navigateToCreatorHome = (refresh = false, skipAltRoute = false) => {
    var refreshParamsStr = "false"
    if (refresh)
      refreshParamsStr = refresh.toString();

    var navigationExtras: NavigationExtras = {
      queryParams: {
        r: refreshParamsStr
      }
    };


    //if we have an existing route, other than sign-in, 
    var existingPath = this.location.path();
    if (existingPath.indexOf("?") > -1)
      existingPath = existingPath.substring(1, existingPath.indexOf("?"));
    else
      existingPath = existingPath.substring(1);

    //send us to that route after authenication
    if (existingPath.indexOf("sign-in") == -1 && !skipAltRoute)
      this.navCtrl.navigateForward([existingPath], navigationExtras);
    else {
      //otherwise send to review page
      this.navCtrl.navigateForward(['content'], navigationExtras);
    }

    this.loadMenuItems();

  }

  navigateToDesignerHome = (refresh = false, skipAltRoute = false) => {
    var refreshParamsStr = "false"
    if (refresh)
      refreshParamsStr = refresh.toString();

    var navigationExtras: NavigationExtras = {
      queryParams: {
        r: refreshParamsStr
      }
    };


    //if we have an existing route, other than sign-in, 
    var existingPath = this.location.path();
    if (existingPath.indexOf("?") > -1)
      existingPath = existingPath.substring(1, existingPath.indexOf("?"));
    else
      existingPath = existingPath.substring(1);

    //send us to that route after authenication
    if (existingPath.indexOf("sign-in") == -1 && !skipAltRoute)
      this.navCtrl.navigateForward([existingPath], navigationExtras);
    else {
      //otherwise send to review page
      this.navCtrl.navigateForward(['upload'], navigationExtras);

      this.loadMenuItems();
    }

  }

  async savePurchaseMode(mode) {
    this.isPurchaseMode = mode;
    await this.storageService.set("isPurchaseMode", mode);
  }

  async populatePurchaseMode() {
    var isPurchaseMode = await this.storageService.get("isPurchaseMode");
    if (isPurchaseMode != '')
      this.isPurchaseMode = isPurchaseMode;
  }

  async saveIsMultiBrand(mode) {
    this.isMultiBrand = mode;
    await this.storageService.set("isMultiBrand", mode);
  }

  async populateIsMultiBrand() {
    var isMultiBrand = await this.storageService.get("isMultiBrand");
    if (isMultiBrand != '')
      this.isMultiBrand = isMultiBrand;
  }

  async saveIsDesigner(mode) {
    this.isDesigner = mode;
    await this.storageService.set("isDesigner", mode);
  }

  async populateIsDesigner() {
    var IsDesigner = await this.storageService.get("isDesigner");
    if (IsDesigner != '')
      this.isDesigner = IsDesigner;
  }

  async saveIsAdministrator(isAdmin) {
    this.isAdministrator = isAdmin;
    await this.storageService.set("isAdministrator", isAdmin);
  }

  async populateIsAdministrator() {
    var isAdministrator = await this.storageService.get("isAdministrator");
    if (isAdministrator != '')
      this.isAdministrator = isAdministrator;
  }

  async saveHasPurchased(hasPurchased) {
    this.hasPurchased = hasPurchased;
    await this.storageService.set("hasPurchased", hasPurchased);
  }

  async populateHasPurchased() {
    var hasPurchased = await this.storageService.get("hasPurchased");
    if (hasPurchased != '')
      this.hasPurchased = hasPurchased;
  }

  async saveDownloadUrl(downloadUrl) {
    this.downloadUrl = downloadUrl;
    await this.storageService.set("downloadUrl", downloadUrl);
  }

  async saveIsDownloading(isDownloading) {
    if(!isDownloading)
      this.downloadProgress = 0.05;

    this.isDownloading = isDownloading;
    // await this.storageService.set("isDownloading", isDownloading);
  }

  async saveIsUploading(isUploading) {
    if(!isUploading)
      this.downloadProgress = 0.05;

    this.isUploading = isUploading;
    // await this.storageService.set("isDownloading", isDownloading);
  }

  async populateDownloadInfo() {
    var downloadUrl = await this.storageService.get("downloadUrl");
    if (downloadUrl != '')
      this.downloadUrl = downloadUrl;

    // var isDownloading = await this.storageService.get("isDownloading");
    // if (isDownloading != '')
    //   this.isDownloading = isDownloading;
  }

  async trackGoogleAnalyticsUserID() {
    var uid = await this.storageService.get("googleAnalyticsUserId");
    if (uid == '') {
      var utils = new Utils();
      uid = utils.guid();
      await this.storageService.set("googleAnalyticsUserId", uid);
    }
    return uid;
  }

  async saveUserName(username) {
    this.userName = username;
    await this.storageService.set("userName", username);
  }

  async populateUserName() {
    var userName = await this.storageService.get("userName");
    if (userName != '')
      this.userName = userName;
  }

  async saveUserId(userId) {
    this.userId = userId;
    await this.storageService.set("userId", userId);
  }

  async populateUserId() {
    var userId = await this.storageService.get("userId");
    if (userId != '')
      this.userId = userId;
  }

  async saveAuthToken(idToken) {
    return new Promise<void>(async (resolve, reject) => {
      this.idToken = idToken;
      await this.storageService.set("authToken", idToken);
      resolve()
    })
  }

  async populateAuthToken() {
    return new Promise<void>(async (resolve, reject) => {
      var idToken = await this.storageService.get("authToken");
      if (idToken != '')
        this.idToken = idToken;

      resolve();
    });
  }

  async populateWifiOnlySetting() {
    var setting = await this.storageService.get("wifi-only-download");
    if (setting != '')
      this.wifiOnlyDownload = setting;
  }

  async saveBrandId(brandId) {
    this.brandId = brandId;
    await this.storageService.set("brandId", brandId);
  }

  async populateBrandId() {
    return new Promise<void>(async (resolve, reject) => {
      var brandId = await this.storageService.get("brandId");
      if (brandId != '')
        this.brandId = brandId;

      resolve();
    });
  }

  async saveBrandData(brandInfo) {
    this.brandInfo = brandInfo;
    await this.storageService.set("brandInfo", JSON.stringify(brandInfo));
  }

  async populateBrandData() {
    return new Promise<void>(async (resolve, reject) => {
      var brandInfo = await this.storageService.get("brandInfo");
      if (brandInfo != '')
        this.brandInfo = JSON.parse(brandInfo);
      resolve();
    });
  }

  handleNetworkStatus(ignoreConnected = false) {
    if (!this.networkStatus.connected) {
      this.notConnectedAction();
    }
    else if (this.networkStatus.connected && !ignoreConnected) {
      this.toast.presentToast("Internet is back!", 1500);
      this.navigateToCreatorHome(false);
    }
  }

  logout(clearCreds = true) {
    if(clearCreds){
      this.saveAuthToken("");
      this.saveBrandId("");
      this.saveIsAdministrator(false);
      this.saveHasPurchased(false);
    }
    this.loggedIn = false;
    this.navigateToSignIn();
  }

  resetCurrentBrand() {
    this.saveBrandId("");
    this.navigateToCreatorHome(true);
  }


  checkIfLoggedIn() {
    return new Promise<boolean>(async (resolve, reject) => {
        this.aws.checkSessionValid(this.userName, 
          //success callback
          () => {
          this.loggedIn = true;

          //navigate to appropriate home page
          if(this.isDesigner)
            this.navigateToDesignerHome(true);
          else
            this.navigateToCreatorHome(true);

          resolve(this.loggedIn);
        }, 

        //error callback
        () => {
          this.loggedIn = false;
          resolve(this.loggedIn);
        }
      );
  });
}

  notConnectedAction(): void {
    var navigationExtras: NavigationExtras = {
      queryParams: {
        m: "This app requires an internet connection. Please check your connection."
      }
    };
    this.navCtrl.navigateForward(['splash'], navigationExtras);
  }

  showIssue(m): void {
    var navigationExtras: NavigationExtras = {
      queryParams: {
        m: m
      }
    }
    this.navCtrl.navigateForward(['splash'], navigationExtras);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  handleHttpError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    this.logout(true);
    return errorMessage;
  }

}