import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent  {

  constructor(
    public toastController: ToastController, 
    public navCtrl: NavController ) {}

  async presentToast(message, durationInMilliseconds) {
    const toast = await this.toastController.create({
      message: message,
      duration: durationInMilliseconds
    });
    toast.present();
  }

  async presentDownloadCompleteToastWithOptions(header, leftHandler) {
    const toast = await this.toastController.create({
      header: header,
      position: 'bottom',
      cssClass: "toast-note",
      buttons: [
        {
          side: 'start',
          icon: 'image-outline',
          text: 'Photos',
          handler: () => {
            if(leftHandler)
              leftHandler();
           
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  async presentDownloadFailedToastWithOptions(header, leftHandler, rightHandler) {
    const toast = await this.toastController.create({
      header: header,
      position: 'bottom',
      cssClass: "toast-note",
      buttons: [
        {
          side: 'start',
          icon: 'bug-outline',
          text: 'Error',
          handler: () => {
            if(leftHandler)
              leftHandler();
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            if(rightHandler)
              rightHandler();
          }
        }
      ]
    });
    toast.present();
  }

  async presentDownloadSettingsToastWithOptions(header) {
    const toast = await this.toastController.create({
      header: header,
      position: 'bottom',
      cssClass: "toast-note",
      buttons: [
        {
          side: 'start',
          icon: 'settings-outline',
          text: 'Settings',
          handler: () => {
            var navigationExtras: NavigationExtras = {
              queryParams: {
                  settingId: "1",
              }
            };
             this.navCtrl.navigateForward(['settings-detail'], navigationExtras);
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

}
