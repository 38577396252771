import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class Utils {
constructor( 
) {

}

preloadImagesList:any[] = null;;
preloadImages(array, waitForOtherResources, timeout = null) {
    if (!this.preloadImagesList) {
        this.preloadImagesList = [];
    }
    var loaded = false, list = this.preloadImagesList, imgs = array.slice(0), t = timeout || 15*1000, timer;
    if (!waitForOtherResources || document.readyState === 'complete') {
        loadNow();
    } else {
        window.addEventListener("load", function() {
            clearTimeout(timer);
            loadNow();
        });
        // in case window.addEventListener doesn't get called (sometimes some resource gets stuck)
        // then preload the images anyway after some timeout time
        timer = setTimeout(loadNow, t);
    }

    function loadNow() {
        if (!loaded) {
            loaded = true;
            for (var i = 0; i < imgs.length; i++) {
                var img = new Image();
                img.onload = img.onerror = img.onabort = function() {
                    var index = list.indexOf(this);
                    if (index !== -1) {
                        // remove image from the array once it's loaded
                        // for memory consumption reasons
                        list.splice(index, 1);
                    }
                }
                list.push(img);
                img.src = imgs[i];
            }
        }
    }
}

guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

sleep(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

randomIntBetween(min, max) { 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

//delete empty props
clean(obj) {
    for (var propName in obj) { 
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

}

