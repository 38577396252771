import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { Globals } from './providers/globals'
import { ContentSetGlobal } from './providers/contentset-global'
import { ToastNotificationComponent } from './pages/toast-notification/toast-notification.component';
import { SettingsPopoverModule} from './components/settings-popover/settings-popover.module';
import { CreateRequestGuardService } from './guards/guard.service'

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SettingsPopoverModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser, 
    SplashScreen, 
    StatusBar, 
    Globals, 
    ContentSetGlobal, 
    ToastNotificationComponent, 
    // SettingsPopoverComponent, 
    CreateRequestGuardService
    // { provide: APP_INITIALIZER, useFactory: globalFactory, deps: [Globals], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function globalFactory(globals: Globals) {
  return () => globals.login();
}